export const mixins = {
  data() {
    return {
      positionList: [],
    };
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(() => {
      this.linetoscroll();
    });
  },
  methods: {
    // 每轮基线的位置
    linetoscroll() {
      let list = [];
      for (let i = 0; i < this.videoList.length; i++) {
        let ps = this.$refs["deo" + i][0];
        list.push({ ids: i, top: this.soletop(ps) * i });
      }
      this.positionList = list;
    },
    //  返回当前组件距离顶部的距离
    soletop(comref) {
      // console.log(comref.$el.getBoundingClientRect(), "当前属性");
      return comref.$el.getBoundingClientRect().height;
    },
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
};
