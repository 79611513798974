<template>
  <div>
    <div v-if="!isMobile">
      <div v-for="(item, index) in videoList" :key="index">
        <transtationdetial
          :item="item"
          :ref="'deo' + index"
          @video_runpage="video_runpage"
        ></transtationdetial>
      </div>
    </div>

    <div v-if="isMobile">
      <div v-for="(item, index) in videoList" :key="index">
        <mobidetiali
          :item="item"
          :ref="'deo' + index"
          @video_runpage="video_runpage"
        ></mobidetiali>
      </div>
    </div>
  </div>
</template>
  
  <script>
import transtationdetial from "@/components/pc/detialitems";

import p1 from "@/assets/images/programme/p1.jpg";
import p2 from "@/assets/images/programme/p2.mp4";
import p3 from "@/assets/images/programme/p3.mp4";
import p4 from "@/assets/images/programme/p4.png";
// 手机
import mobidetiali from "@/components/mobile/mobidetiali";
import mp2 from "@/assets/images/programme/mp2.jpg";
import mp3 from "@/assets/images/programme/mp3.jpg";

import mp4 from "@/assets/images/programme/mp4.jpg";

import { mixins } from "@/common/scrollmixin.js";
import { look_mixin } from "@/utils/looktime.js";
import { mapGetters } from "vuex";
export default {
  components: {
    transtationdetial,
    mobidetiali,
  },
  mixins: [mixins, look_mixin],
  computed: {
    ...mapGetters(["isMobile"]),
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.isMobile) {
      this.videoList[3].src = mp4;
    }
  },
  data() {
    return {
      videoList: [
        {
          id: 1,
          title: "",
          title1: "项目概览",
          title5: "集中式光伏风力电站",
          desc: "",
          src: p1,
          isvideo: false,
          pages: false,
          runurl: "/transtationdetial",
          img: p1,
          detial:
            "集中式大型并网光伏电站就是利用偏远地区的贫瘠、荒废的土地,集中建设大型光伏电站,发电直接并入公共电网,接入高压输电系统供给远距离负荷。",
        },
        {
          id: 2,
          title: "",
          title1: "零碳园区",
          title5: "",
          desc: "",
          src: p2,
          img: mp2,
          isvideo: true,
          pages: false,
          runurl: "/transtationdetial",
          detial:
            "零碳园区是一种以分布式光伏、分散式风电、储能系统、充电桩等系统为底层，以云计算管理系统为大脑，将清洁电源部署在用户附近，以实现区域内零碳排放的新型能源生态。它具有清洁、灵活、安全、共享、智能等特点，代表着未来能源的发展方向。",
        },
        {
          id: 3,
          title: "",
          title1: "光储充一体停车位",
          title5: "",
          desc: "",
          src: p3,
          img: mp3,
          isvideo: true,
          pages: false,
          runurl: "/transtationdetial",
          detial:
            "光伏、储能和充电设施有机构成了光储充微网系统，实现了微环境下的源荷自给自足，减少了未来电动化交通系统对电网的冲击，提升了交通系统的安全性和健壮性，同时实现了清洁能源汽车用清洁能源电力的终极目标。",
        },
        {
          id: 4,
          title: "",
          title1: "模块化储能",
          title5: "",
          desc: "",
          src: p4,
          isvideo: false,
          pages: false,
          runurl: "/transtationdetial",
          detial:
            "储能是指通过介质或设备把能量存储起来，在需要时再释放的过程。采用模块化的组成结构，可适应多种储能工作环境，放大产品的适用范围。",
        },
      ],
    };
  },
  //销毁,否则跳到别的路由还是会出现
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    video_runpage(e) {
      this.user_btn_click(e.title1, e.runurl, e.btn_name);
    },
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(
      //   "当前位置",
      //   this.scroll % 50 == 0 ? this.scroll : "------------"
      // );
      // 第一个屏幕
      if (this.scroll < this.positionList[1].top + 25) {
        // this.$refs.deo0[0].videoplay();
        this.$refs.deo1[0].videopause();
        this.$refs.deo2[0].videopause();
      }
      // 第二个屏幕
      if (
        this.scroll < this.positionList[2].top - 150 &&
        this.scroll > this.positionList[1].top - 150
      ) {
        // this.$refs.deo0[0].videopause();
        console.log(
          "当前位置",
          this.scroll % 50 == 0 ? this.scroll : "------------",
          this.scroll < this.positionList[2].top - 150 &&
            this.scroll > this.positionList[1].top - 150
        );
        this.$refs.deo1[0].videoplay();
        this.$refs.deo2[0].videopause();
      }
      // 第三个屏幕
      if (
        this.scroll > this.positionList[2].top - 150 &&
        this.scroll < this.positionList[3].top - 150
      ) {
        // this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videopause();
        this.$refs.deo2[0].videoplay();
      }
      // 第四个屏幕
      if (this.scroll > this.positionList[3].top - 150) {
        // this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videopause();
        this.$refs.deo2[0].videopause();
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.imgbox {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
</style>