<template>
  <div class="imgbox">
    <div style="height: 100vh">
      <video
        v-show="item.isvideo"
        :poster="item.img ? item.img : ''"
        :src="item.src"
        ref="video1"
        id="video"
        :muted="true"
        playsinline
        preload="metadata"
        style="clear: both; display: block"
        :style="styleObj"
        loop
        webkit-playsinline
        WebKit-playsinlinetrue
        playsinlinetrue
        x5-video-player-type="h5"
      ></video>
      <!-- controls -->
      <img v-show="!item.isvideo" :src="item.src" alt="" />
    </div>

    <!-- 下面的显示 -->
    <div class="pl20 pr20 pb30 absolute left bottom bgsilces color-white">
      <div class="flex flex-between align-items-center pt30 pb30">
        <div class="fs22 fw600">{{ item.title1 }}</div>
        <div
          class="b-a-1 fs12 br5 pt5 pb5 text-center w110px changheand"
          @click="runpage(item, 2)"
        >
          订阅更新
        </div>
      </div>
      <div
        class="boxtoimg flex flex-wrap flex-between align-items-center"
        style=""
        v-if="item.pages"
      >
        <div v-for="(atm, neswa) in item.list" :key="neswa">
          <div
            class="flex pb10 pl3 pr3 flex-col align-items-center text-shadow1"
          >
            <div class="fs30" :class="atm.font"></div>
            <div class="fs12">{{ atm.name }}</div>
          </div>
        </div>
      </div>
      <div v-else class="">
        {{ item.detial }}
      </div>
    </div>

    <!-- 这是头部上面的标题 -->
    <div class="banner-titles absolute axis-x-center text-shadow color-white">
      <div class="fs26 width-full text-center hoverclass letter-5">
        {{ item.title5 }}
      </div>
      <div class="fs18 width-full pt20 text-center hoverclass letter-5">
        {{ item.desc }}
      </div>
    </div>
  </div>
</template>
      
  <script>
export default {
  props: ["item"],
  data() {
    return {
      videoplayer: null,
      styleObj: {
        // width: "100vw",
        // height: "90vh",
      },
    };
  },
  mounted() {
    // setTimeout(() => {
    this.videoplayer = this.$refs.video1;
    // console.log("视频加载出来了", this.videoplayer.src);
    // }, 500);
  },
  methods: {
    runpage(path, other) {
      // console.log("跳转页面，", path);
      let name = "";
      if (other == 1) {
        name = "了解更多";
      }
      if (other == 2) {
        name = "订阅更新";
        path.runurl = "/addpreore";
      }
      path.btn_name = name;
      this.$emit("video_runpage", path);
      this.$router.push(path.runurl);
    },
    videoplay() {
      if (this.item.isvideo) {
        // console.log("视频开始播放了", this.videoplayer.src);
        this.videoplayer.play();
      }
    },
    videopause() {
      if (this.item.isvideo) {
        this.videoplayer.pause();
      }
    },
  },
};
</script>
      
    <style lang="scss" scoped>
.banner-titles {
  top: 200px;
  width: 100%;
  // letter-spacing: 5px;
}
.box1200auto {
  width: 1200px;
  margin: 0 auto;
}
.w100px {
  width: 230px;
}
.w110px {
  width: 100px;
}
.boxtoimg {
  width: 100%;
  // height: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.imgbox {
  width: 100%;
  height: 100vh;
  position: relative;
  .bgsilces {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background: rgba($color: #000000, $alpha: 0.6);
  }
  .videosa {
    bottom: 150px;

    .more {
      background: #e8e9eb;
      //   padding: 10px 50px;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      margin-right: 100px;
    }
    .more1 {
      background: #e8e9eb;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
    }
  }
  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.playnone {
  display: none;
}
</style>