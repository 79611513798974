<template>
  <div class="imgbox">
    <div style="height: 80vh">
      <video
        v-show="item.isvideo"
        loop
        ref="video1"
        id="video"
        poster
        :muted="true"
        preload="metadata"
        style="clear: both; display: block"
        :style="styleObj"
        :src="item.src"
      ></video>
      <img v-show="!item.isvideo" :src="item.src" alt="" />
    </div>
    <!-- 下面的显示 -->
    <div class="flex box1200auto align-items-center" style="height: 20vh">
      <div>
        <div class="fs22 w100px fw600">{{ item.title1 }}</div>
        <div
          class="b-a-1 mt20 fs12 br5 pt5 pb5 text-center w110px changheand"
          @click="runpage(item, 2)"
        >
          订阅更新
        </div>
      </div>
      <div
        class="boxtoimg flex flex-around align-items-center pr100"
        v-if="item.pages"
      >
        <div v-for="atm in item.list" :key="atm.id">
          <div
            class="flex flex-col align-items-center text-shadow1"
            style="color: #666666"
          >
            <div class="fs40 pb10" :class="atm.font"></div>
            <div class="fs14">{{ atm.name }}</div>
          </div>
        </div>
      </div>
      <div v-else class="pl100 pb50">
        {{ item.detial }}
      </div>
    </div>
    <!-- 这是头部上面的标题 -->
    <div class="banner-titles absolute axis-x-center text-shadow color-white">
      <div class="fs36 width-full text-center hoverclass letter-5">
        {{ item.title }}
      </div>
      <div class="fs18 width-full pt20 text-center hoverclass letter-5">
        {{ item.desc }}
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  props: ["item"],
  data() {
    return {
      videoplayer: null,
      styleObj: {
        // width: "100vw",
        // height: "90vh",
      },
    };
  },
  mounted() {
    // setTimeout(() => {
    this.videoplayer = this.$refs.video1;
    // }, 500);
  },
  methods: {
    runpage(path, other) {
      // console.log("跳转页面，", path);
      let name = "";
      if (other == 1) {
        name = "了解更多";
      }
      if (other == 2) {
        name = "订阅更新";
        path.runurl = "/addpreore";
      }
      path.btn_name = name;
      this.$emit("video_runpage", path);
      this.$router.push(path.runurl);
    },
    videoplay() {
      if (this.item.isvideo) {
        this.videoplayer.play();
      }
    },
    videopause() {
      if (this.item.isvideo) {
        this.videoplayer.pause();
      }
    },
  },
};
</script>
    
  <style lang="scss" scoped>
.banner-titles {
  top: 200px;
  width: 100%;
  // letter-spacing: 5px;
}
.box1200auto {
  width: 1200px;
  margin: 0 auto;
}
.w100px {
  width: 230px;
}
.w110px {
  width: 100px;
}
.boxtoimg {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.imgbox {
  width: 100%;
  height: 100vh;
  position: relative;
  .videosa {
    bottom: 150px;

    .more {
      background: #e8e9eb;
      //   padding: 10px 50px;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      margin-right: 100px;
    }
    .more1 {
      background: #e8e9eb;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
    }
  }
  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.playnone {
  display: none;
}
</style>